/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getMetersPerVerticalUnitForSR as n } from "../../core/unitUtils.js";
import { isSphericalPCPF as e } from "../ellipsoidUtils.js";
import { equals as t } from "./spatialReferenceUtils.js";
function o(o, f, u) {
  if (null == f || null == u || u.vcsWkid || t(f, u) || e(f) || e(u)) return null;
  const a = n(f) / n(u);
  if (1 === a) return null;
  switch (o) {
    case "point":
    case "esriGeometryPoint":
      return n => r(n, a);
    case "polyline":
    case "esriGeometryPolyline":
      return n => l(n, a);
    case "polygon":
    case "esriGeometryPolygon":
      return n => i(n, a);
    case "multipoint":
    case "esriGeometryMultipoint":
      return n => s(n, a);
    case "extent":
    case "esriGeometryEnvelope":
      return n => c(n, a);
    default:
      return null;
  }
}
function r(n, e) {
  null != n?.z && (n.z *= e);
}
function i(n, e) {
  if (n) for (const t of n.rings) for (const n of t) n.length > 2 && (n[2] *= e);
}
function l(n, e) {
  if (n) for (const t of n.paths) for (const n of t) n.length > 2 && (n[2] *= e);
}
function s(n, e) {
  if (n) for (const t of n.points) t.length > 2 && (t[2] *= e);
}
function c(n, e) {
  n && null != n.zmin && null != n.zmax && (n.zmin *= e, n.zmax *= e);
}
export { o as getGeometryZScaler };